<template>
  <div class="text-center w-100">
    <TTBtn
      color="white"
      elevation="1"
      fab
      depressed
      large
      data-test="aside-right-config-edit"
      class="mb-2"
      @click="$root.$emit('showConfigForm')"
    >
      <VIcon
        color="tt-black"
        size="19"
      >
        fal fa-edit
      </VIcon>
    </TTBtn>
    <div class="tt-text-caption tt-black--text text--lighten-2 mb-6">
      Редакт.<br>
      настройки
    </div>
    <template v-if="showPolitics">
      <TTBtn
        large
        fab
        depressed
        color="white"
        elevation="1"
        data-test="aside-right-politics-edit"
        @click="$root.$emit('showPoliticsForm')"
      >
        <VIcon
          color="tt-black"
          size="19"
        >
          fal fa-file-alt
        </VIcon>
      </TTBtn>
      <div class="tt-text-caption tt-black--text text--lighten-2">
        Редакт.<br>
        политики
      </div>
    </template>
  </div>
</template>
<script>

import { mapGetters } from 'vuex';

export default {
  name: 'AppConfigAsideRight',
  data() {
    return {
      general: {},
    };
  },
  computed: {
    ...mapGetters('customerInfo', ['isSupervisor']),
    showPolitics() {
      return this.isSupervisor;
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
